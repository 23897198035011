.red {
  border: solid 4px red;
}

.green {
  border: solid 4px green;
}

img {
  margin-left: 5px;
  margin-right: 5px;
}

.zoom {
  z-index: 50;
  transform: scale(1.25);
  padding-top: 10px;
  padding-right: 10px;
}

.left {
  padding-left: 5px;
}

.right {
  padding-left: 60px;
}

.Image-Fallback-Container {
  text-align: center;
}

.Image-Fallback-Text {
  margin-top: 0px;
}
