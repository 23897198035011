.IdDetailsComparison-Container {
  display: inline-block;
  padding: 5px;
}
.IdDetailsComparison-Title-Holder {
  position: relative;
}

.IdDetailsComparison-Icon {
  display: inline-block;
  position: absolute;
  top: 3px;
  padding-left: 5px;
}

.IdDetailsComparison-Tag {
  text-align: center;
  font-size: small;
  font-weight: bold;
  margin-top: 5px;
}

.IdDetailsComparison-Title {
  display: inline-block;
  text-align: left;
  font-size: small;
  font-weight: bold;
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.IdDetailsComparison-Flex-Container {
  display: inline-flex;
  align-items: center;
}

.IdDetailsComparison-Details {
  flex-direction: column;
  text-align: left;
  margin-left: 55px;
  margin-right: 5px;
}

.IdDetailsComparison-AppImage {
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
}

.IdDetailsComparison-Flex-SubContainer {
  display: flex;
}

.IdDetailsComparison-DBImage {
  flex-direction: column;
  margin-left: 3px;
  margin-right: 3px;
}

.IdDetailsComparison-NormalText {
  font-size: small;
  font-weight: normal;
}

.IdDetailsComparison-Bold {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}

.IdDetailsComparison-SubTitle-Bold {
  font-weight: bold;
}

.IdDetailsComparison-Red {
  color: red;
}

.IdDetailsComparison-Green {
  color: green;
}

.IdDetailsComparison-Details table {
  font-size: small;
  margin-bottom: 10px;
}

.IdDetailsComparison-Hover {
  display: flex;
}

.IdDetailsComparison-Hover:hover img {
  height: 350px;
}
