.nav_bar {
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.search_box {
  width: min(100%, 38rem);
  padding: 0.5rem 0.5rem;
  background-color: white;
  border-radius: var(--var--border--radius);
  box-shadow: 0px 1px 1px var(--clr--shadow);
}
.search_box input {
  height: 100%;
  width: 80%;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  padding: 0.5em 0.5em;
  border: none;
  background-color: transparent;
}
.search_box input::placeholder {
  font-size: clamp(0.9rem, 1.5vw, 1rem);
}
.search_box button {
  width: 20%;
  font-size: clamp(0.9rem, 1.5vw, 1rem);
  padding: 0.5em;
  border: none;
  cursor: pointer;
  background-color: var(--clr--primary--300);
  border-radius: var(--var--border--radius);
  color: white;
}
.search_box button:hover {
  background-color: var(--clr--primary--600);
}
