.alert {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  width: fit-content;
  height: 50px;
  border-radius: var(--var--border--radius);
  z-index: 15;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin: 1rem 1rem 0rem 0rem;
  gap: 1rem;
  font-size: 1.2rem;
  border: solid 1px transparent;
  opacity: 0;
}
.icon_wrapper {
  height: fit-content;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0.2em;
  font-size: 1.5rem;
}
.success {
  background-color: #c3ffe5;
}
.failure {
  background-color: #ffc7be;
}
.cancel_error {
  font-size: 2rem;
  cursor: pointer;
}
.hide {
  -webkit-animation: hide-1 0.5s forwards;
  animation: hide-1 0.5s forwards;
}
.unhide {
  -webkit-animation: unhide-1 0.5s forwards;
  animation: unhide-1 0.5s forwards;
}
@keyframes hide-1 {
  from {
    opacity: 1;
    z-index: 15;
  }
  to {
    opacity: 0;
    z-index: -15;
  }
}
@keyframes unhide-1 {
  from {
    opacity: 0;
    z-index: -15;
  }
  to {
    opacity: 1;
    z-index: 15;
  }
}
