.IdFaceComparison-Container {
  display: inline-block;
  padding: 5px;
}

.IdFaceComparison-Title {
  display: inline-block;
  text-align: left;
  font-size: small;
  font-weight: bold;
  padding-left: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.IdFaceComparison-Tag {
  text-align: center;
  font-size: small;
  font-weight: bold;
  margin-top: 5px;
}

.IdFaceComparison-Title-Holder {
  position: relative;
}

.IdFaceComparison-Icon {
  display: inline-block;
  position: absolute;
  top: 3px;
  padding-left: 5px;
}

.IdFaceComparison-Flex-Container {
  display: inline-flex;
  align-items: center;
}

.IdFaceComparison-Details {
  flex-direction: row;
  text-align: left;
  margin-left: 55px;
  margin-right: 5px;
}

.IdFaceComparison-AppImage {
  flex-direction: row;
  margin-left: 3px;
  margin-right: 3px;
}

.IdFaceComparison-DBImage {
  flex-direction: row;
  margin-left: 3px;
  margin-right: 3px;
}

.IdFaceComparison-NormalText {
  font-size: small;
  font-weight: normal;
}

.IdFaceComparison-Bold {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}

.IdFaceComparison-Red {
  color: red;
}

.IdFaceComparison-Green {
  color: green;
}

.IdFaceComparison-Black {
  color: black;
}
