.container_sm {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  width: min(100%, 400px);
}

.container_sm_lower {
  flex-grow: 1;
  flex-basis: 0;
  height: fit-content;
  width: min(100%, 400px);
}
.container_wrapper {
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: var(--var--radius);
  box-shadow: 0px 1px 1px var(--clr--shadow);
  overflow-y: scroll;
}
.container_heading {
  width: 100%;
  min-height: 50px;
  font-size: 1.2rem;
  padding-left: 1em;
  font-weight: bold;
  align-items: center;
  border-bottom: solid 3px var(--clr--secondary--300);
}
.container_content {
  width: 100%;
  flex-grow: 1;
}
.container_content table {
  width: 100%;
  height: 100%;
  border-spacing: 2px;
}
.container_content th {
  border: 1px solid var(--clr--border--300);
  text-align: center;
  height: 3rem;
  background-color: #fafaff;
}
.container_content td {
  border: 1px solid var(--clr--border--300);
  text-align: center;
  height: 3rem;
  max-width: 1rem;
}
.col_s {
  width: 20%;
}
.col_l {
  width: 30%;
}

@media (max-width: 1550px) {
  .container_heading {
    font-size: 1rem;
    padding-left: 1em;
  }
  .container_content table {
    font-size: 0.9rem;
  }
}
