a {
  text-decoration: none;
  height: fit-content;
  width: min(500px, 100%);
}
.card {
  height: fit-content;
  border-radius: var(--var--border--radius);
  flex-direction: column;
  cursor: pointer;
  border: solid 2px transparent;
  padding: 1rem;
}
.card:hover {
  border: solid 2px rgb(231, 231, 231);
  box-shadow: 0em 0em 2em #e6e6e6;
}
.icon {
  height: 200px;
  width: 100%;
  font-size: 1.8rem;
  background-color: #fbffdd;
  border-radius: 5px;
  color: #fbc900;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.heading {
  font-size: 2rem;
  font-weight: 800;
  color: #0e0d35;
}
.sub_heading {
  font-size: 1.4rem;
  color: #9e9e9e;
}
