._ {
  width: 100%;
  height: 100vh;
}

.display_flex {
  display: flex;
}

.display_flex div {
  display: flex;
}

.center {
  justify-content: center;
  align-items: center;
}

.container_internal {
  text-align: center;
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
}

.container > div {
  display: flex;
}

.container *,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.open_container *,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.content {
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - 68px);
  background-color: var(--clr--secondary--300);
  height: 100vh;
  overflow-y: auto;
  flex-direction: column;
}
