._ {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.wrapper {
  width: min(95%, 450px);
  height: fit-content;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--clr--white);
  box-shadow: 0 0 2rem var(--clr--shadow);
  border-radius: var(--var--border--radius);
  padding: 2rem 1rem;
}
.wrapper h2 {
  font-size: clamp(2rem, 3vw, 4rem);
  padding: 0em 0rem 0.6em 0em;
}
.input_box {
  font-size: clamp(1rem, 2vw, 1.2rem);
  height: 3em;
  width: 90%;
  padding: 1em 1em;
  margin: 0.9em 0em;
  border: solid 2px var(--clr--border--300);
  border-radius: var(--var--border--radius);
  background-color: var(--clr--white);
}

.input_box::placeholder{
  font-size: clamp(1rem, 2vw, 1.2rem);
}

.btn {
  border: none;
  font-weight: bold;
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  width: 90%;
  height: 2.5em;
  margin: 1.5em 0em 1em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr--primary--300);
  color: var(--clr--white);
  border-radius: var(--var--border--radius);
  cursor: pointer;
}
.btn:hover {
  background-color: var(--clr--primary--600);
}
