.tag {
  padding: 0.4rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4rem;
  border: solid 2px transparent;
  background-color: white;
  box-shadow: 0px 1px 1px var(--clr--shadow);
}
.tag_key {
  padding-right: 1rem;
}
.tag_value {
  display: flex;
  justify-content: center;
  align-items: center;
}
