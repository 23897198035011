.container {
  flex-grow: 1;
  width: 100%;
  gap: 1rem;
  flex-direction: column;
  height: fit-content;
}
.result_wrapper {
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
}
.row_1 {
  height: 50%;
}
.row_2 {
  height: 50%;
}
