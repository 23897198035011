.label {
  width: 100%;
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 2px var(--clr--secondary--300);
  border-radius: var(--var--border--radius);
  margin-right: 0rem;
}
.label-value {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
