.list_page {
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}
.list_page_table_container {
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.loader_wrapper {
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.list_page_nav {
  margin-left: auto;
  order: 2;
  padding-right: 2rem;
}

.list_page_nav span {
  padding: 0.5rem 0.5rem;
  background-color: white;
  border: solid 1px var(--clr--secondary--300);
  cursor: pointer;
  font-size: small;
}

.list_page_loader {
  flex-grow: 1;
  flex-wrap: wrap;
  text-align: center;
}

.list_page_table table {
  flex-grow: 1;
  flex-wrap: wrap;
}

.list_page_table th {
  border: 1px solid var(--clr--border--300);
	text-align: center;
	height: 3rem;
  background-color: white;
}

.list_page_table td {
  font-size: small;
  border: 1px solid var(--clr--border--300);
	text-align: center;
	height: 3rem;
	max-width: 1rem;
}

.list_page_table td,
th,
tr {
  flex-grow: 1;
  flex-wrap: wrap;
}
