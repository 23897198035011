.container {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.section {
  width: 100%;
}
/* Feedback section */
.feedback {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}
