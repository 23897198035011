*:focus {
	outline: none;
}

:root {
	/* Colours */
	--clr--primary--100: #83dcee;
	--clr--primary--300: #28a4bc; /* main */
	--clr--primary--600: #10869e;
	--clr--primary--900: #003742;

	--clr--white: white;
	--clr--secondary--300: #f4f4f7; /* main */

	--clr--shadow: rgb(226, 226, 226);
	--clr--loader--fill: #28a4bc;
	--clr--loader--bg: white;
	--clr--border--300: #e4e4e4;

	--clr--txt--100: white;
	--clr--txt--300: rgb(218, 218, 218);
	--clr--txt--600: #9fa8b3;
	--clr--txt--900: black;

	/* Constants */
	--var--border--radius: 6px;

}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
::-webkit-scrollbar {
	width: 0px;
}
