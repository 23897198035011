.container_lg {
  flex-grow: 2;
  flex-basis: 0;
  height: 100%;
  width: min(100%, 700px);
}
.container_wrapper {
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: var(--var--border--radius);
  box-shadow: 0px 1px 1px var(--clr--shadow);
}
.container_heading {
  width: 100%;
  min-height: 50px;
  font-size: 1.2rem;
  padding-left: 1em;
  font-weight: bold;
  align-items: center;
  border-bottom: solid 3px var(--clr--secondary--300);
}
.container_content {
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  padding: 1em 2em;
  gap: 2.5em;
}
.query {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.query span {
  font-size: 1rem;
  padding: 0.2em 0em;
}
.query_wrapper {
  width: 100%;
  flex-grow: 1;
  border: dashed 2px var(--clr--primary--300);
  justify-content: center;
  align-items: center;
}

.img_size {
  width: 85%;
  height: 90%;
  margin: auto;
  max-height: 16rem;
  max-width: 15rem;
}
.db {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.db span {
  font-size: 1rem;
  padding: 0.2em 0em;
}
.db_wrapper {
  width: 100%;
  flex-grow: 1;
  border: dashed 2px var(--clr--secondary--300);
  justify-content: center;
  align-items: center;
}
.result {
  flex-grow: 1;
  flex-basis: 0;
  height: fit-content;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 1550px) {
  .container_heading {
    font-size: 1rem;
  }
  .container_content {
    padding: 0em 2em 0.5em 2em;
    gap: 2em;
  }
}
