._ {
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 2rem 10%;
  flex-direction: column;
  overflow-y: auto;
}
.display_flex {
  display: flex;
}
.display_flex div{
  display: flex;
}
.center {
  justify-content: center;
  align-content: center;
}
.flex_item{
  flex-grow: 1;
  flex-wrap: wrap;
  flex-direction: column;
}
.section {
  width: 100%;
  height: fit-content;
  display: flex;
}
.header {
  font-size: calc(1.325rem + 1.5vw);
  flex-wrap: wrap;
  padding-bottom: 0.5em;
  flex-direction: column;
  width: fit-content;
}
.heading {
  font-weight: 500;
  color: var(--clr--primary--900);
  padding-bottom: 0.2em;
}
.sub_heading {
  color: var(--clr--primary--100);
  font-size: 1.125rem;
}

/* Content section */
.content {
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1rem;
}

/* Feedback section */
.feedback {
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 2rem 0rem 0rem 0rem;
}

@media (max-width: 1750px) {
  ._ {
    padding: 1rem 7%;
  }
}

@media (max-width: 1550px) {
  ._ {
    padding: 1rem 5%;
  }
}
@media (max-width: 1350px) {
  ._ {
    padding: 1rem 2%;
  }
}
