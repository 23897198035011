.SearchBar {
  overflow: hidden;
  border-bottom: solid 1px black;
  position: relative;
}

.SearchBar-Input {
  width: 25%;
  height: 50%;
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 10px;
  font-size: small;
  text-align: center;
}

.SearchBar-Input:focus {
  outline: none;
}

::-webkit-input-placeholder {
  font-size: small;
}

.SearchBar-Debug {
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 6px;
  right: 8px;
}

.SearchBar-ScreenShot {
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 6px;
  left: 6rem;
}

.SearchBar-Logout{
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 6px;
  left: 8px;
}

.SearchBar-Debug:focus {
  outline: none;
}

.SearchBar-Debug-On {
  background-color: #845ec2;
  color: white;
}

.SignOut {
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
  position: absolute;
  top: 6px;
  left: 7rem;
}
