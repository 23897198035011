._ {
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding: 1rem 9%;
  flex-direction: column;
  overflow-y: auto;
}

._ div {
  display: flex;
}

.section {
  width: 100%;
  height: fit-content;
}

/* Header section */
.header {
  font-size: calc(1.325rem + 1.5vw);
  flex-wrap: wrap;
  padding-bottom: 1rem;
  width: 100%;
}
.heading_wrapper {
  width: fit-content;
  flex-direction: column;
}
.heading {
  font-weight: 500;
  color: var(--clr--primary--900);
  padding-bottom: 0.1em;
}
.heading span {
  color: var(--clr--primary--300);
}
.sub_heading {
  color: var(--clr--txt--300);
  font-size: 1.125rem;
}
.option_wrapper {
  flex-grow: 1;
  min-width: fit-content;
}

/* Content section */
.content {
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: column;
  height: 90%;
}
@media (max-width: 1750px) {
  ._ {
    padding: 1rem 7%;
  }
}

@media (max-width: 1550px) {
  ._ {
    padding: 1rem 5%;
  }
}
@media (max-width: 1350px) {
  ._ {
    padding: 1rem 2%;
  }
}
