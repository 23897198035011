.tag_nav_bar {
  height: fit-content;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 1rem;
}
.tag_bar {
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}
