._ {
  width: 100%;
  height: 100vh;
}
._ div {
  display: flex;
}
.section {
  width: 100%;
  height: 100%;
  position: relative;
}
.left {
  width: 68px;
  background-color: var(--clr--white);
  height: 100vh;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.left:hover {
  width: 256px;
}
.logo_wrapper {
  margin: 1rem 0rem;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.logo {
  height: 45px;
  content: url('../../../assets/sample/short.png');
}
.left:hover .logo {
  width: 90%;
  content: url('../../../assets/sample/hv_logo.png');
}
.menu_items {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: none;
  flex-direction: column;
  justify-content: center;
}
.menu_items_wrapper {
  display: flex;
  flex-direction: column;
}
.menu_item {
  text-decoration: none;
  width: 100%;
  font-size: 1.8rem;
  height: 60px;
  margin: 5px 0px;
  color: var(--clr--txt--600);
  cursor: pointer;
}
.menu_item_wrapper {
  height: 100%;
  width: 95%;
  margin: auto;
  justify-content: center;
  align-items: center;
  border-radius: 0.4em;
  padding: 1rem 0rem;
}
.menu_item_wrapper:hover {
  background-color: var(--clr--secondary--300);
}
.menu_item_selected {
  border-right: solid 2px var(--clr--primary--300);
  color: var(--clr--primary--300);
}
.menu_item_text {
  display: none;
  font-size: 0.8em;
}
.left:hover .menu_item_wrapper {
  justify-content: flex-start;
  padding: 1rem 0.5em;
}
.left:hover .menu_item_text {
  display: flex;
  padding: 0em 0.8em;
  font-size: 0.6em;
}

.root_menu_items {
  min-height: 120px;
  flex-direction: column;
  justify-content: flex-end;
}
.root_menu_items .menu_item {
  height: 50px;
}

.right {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.233);
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  display: none;
}
.left:hover ~ .right {
  display: flex;
}

@media (max-width: 1550px) {
  .menu_item {
    font-size: 1.6rem;
    height: 50px;
  }
  .root_menu_items {
    min-height: fit-content;
  }
  .root_menu_items .menu_item {
    height: 40px;
  }
}
