.container_sm {
  flex-grow: 1;
  flex-basis: 0;
  height: 100%;
  width: min(100%, 400px);
}
.container_wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: white;
  border-radius: var(--var--border--radius);
  box-shadow: 0px 1px 1px var(--clr--shadow);
}
.container_heading {
  width: 100%;
  min-height: 50px;
  font-size: 1.2rem;
  padding-left: 1em;
  font-weight: bold;
  align-items: center;
  border-bottom: solid 3px var(--clr--secondary--300);
}
.container_content {
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 1em;
}
.verdict_wrapper {
  width: 90%;
  max-width: 20rem;
}
.verdict_wrapper strong {
  width: 100%;
}
.options {
  height: fit-content;
  width: 90%;
  max-width: 20rem;
  justify-content: space-between;
  padding: 1rem 0rem;
}
.select {
  height: 100%;
  width: 30%;
  padding: 0.4rem 0.6rem;
  border: solid 2px var(--clr--border--300);
  border-radius: var(--var--border--radius);
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.select_wrapper {
  justify-content: center;
  align-items: center;
}
.suggestion {
  width: 90%;
  max-width: 20rem;
  flex-direction: column;
}
.suggestion span {
  padding-bottom: 0.4rem;
  font-size: 1.1rem;
}
.suggestion textarea {
  height: fit-content;
  padding: 0.5rem;
  border: solid 2px var(--clr--border--300);
  border-radius: var(--var--border--radius);
}
.btn {
  margin: 1rem;
  width: 90%;
  max-width: 20rem;
  height: 50px;
  justify-content: center;
  align-items: center;
  border: solid 2px var(--clr--primary--300);
  border-radius: var(--var--border--radius);
  color: var(--clr--primary--300);
  font-size: 1.2rem;
  font-weight: 900;
  cursor: pointer;
}
.btn:hover {
  background-color: var(--clr--primary--300);
  color: white;
}
@media (max-width: 1550px) {
  .container_heading {
    font-size: 1rem;
  }
  .container_content {
    padding: 0.5em 1em;
  }
  .suggestion span {
    padding-bottom: 0.4em;
    font-size: 1rem;
  }
  .btn {
    margin: 0.5rem;
    font-size: 1rem;
    height: 40px;
  }
}
