.MismatchBar {
  border-bottom: solid 1px black;
  overflow: hidden;
}

.MismatchBar-Decision {
  font-weight: 400;
}

.MismatchBar-Decision-Holder {
  float: left;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
  margin: 0;
  font-size: small;
  font-weight: bold;
}

.MismatchBar-Toggle-Holder {
  width: 30%;
  display: inline;
}

.MismatchBar-Details-Holder {
  width: 70%;
  float: left;
}

.MismatchBar-Model-Button-Container {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
}

.MismatchBar-Toggle {
  float: right;
  margin-right: 15px;
  margin-top: 3px;
}

.MismatchBar-Toggle-Active {
  padding-top: 3px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0;
}

.MismatchBar-Toggle-Row {
  display: block;
}

.MismatchBar-ArrowBack {
  color: blue;
  height: 10px;
  margin-right: 5px;
  cursor: pointer;
}

.MismatchBar-ArrowBack:hover {
  height: 20px;
}

.MismatchBar-ArrowForward {
  color: blue;
  height: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.MismatchBar-ArrowForward:hover {
  height: 20px;
}

.MismatchBar-Field {
  font-weight: 400;
  font-size: small;
  margin: 0;
  cursor: pointer;
}

.MismatchBar-Model-Button {
  background-color: white;
  border-radius: 5px;
  border-width: 1px;
  cursor: pointer;
  padding-top: 3px;
  padding-bottom: 3px;
}

.MismatchBar-Model-Button:focus {
  outline: none;
}
