.navigation {
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--clr--white);
  border-radius: 4rem;
  padding: 0.2rem;
  box-shadow: 0px 1px 1px var(--clr--shadow);
}
.navigation_wrapper {
  font-size: 0.8vw;
  padding: 0.2rem 0.5rem;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.selected {
  background-color: var(--clr--secondary--300);
}
.navigation_wrapper span {
  padding: 0rem 1rem;
}
.navigation_wrapper button {
  border-radius: 4rem;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  border: none;
  background-color: transparent;
}
