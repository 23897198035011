.ApplicationBar {
  border-bottom: solid 1px black;
  overflow: hidden;
}

.ApplicationBar-Title {
  font-weight: bold;
  display: inline;
  float: left;
  margin-left: 15px;
  font-size: small;
}

.ApplicationBar-Content {
  font-weight: 400;
  font-size: small;
}

.ApplicationBar-Field {
  font-weight: 400;
  font-size: small;
  margin: 0;
  cursor: pointer;
}

.ApplicationBar-Active {
  font-weight: bold;
}

